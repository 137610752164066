<template>
  <v-container>
    <!-- <div style="margin-top: 20px; margin-bottom: 20px; margin-left: auto; margin-right: auto; height: 200px; width: 80%; overflow-y: auto; overflow-x: auto; border-style: solid; border-width: 1px; padding-top: 10px; padding-left: 10px; padding-bottom: 10px; padding-right: 10px"> -->
    <!-- <v-card elevation="6" height="200px" style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; padding-right: 10px; overflow-y: auto"> -->
    
      
    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <h2 class="ml-4 mr-4 mb-0 font-weight-bold">{{cuestionario.Titulo}}</h2>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <img v-bind:src="'https://d23esi1h40dfmi.cloudfront.net/wp-content/uploads/2023/04/04110613/GIF-formulario.gif'" />
      </v-col>
    </v-row>
   
    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <p class="ml-8 mr-8" v-html="cuestionario.Descripcion"></p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <p class="ml-8 mr-8" v-html="'Hola, ' + this.$store.state.per.PerNom"></p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <p class="ml-8 mr-8">¿Vas a asistir al evento?</p></v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="respuestas.asistencia.respuestaUsuario" @change="onChangeIntolerancias">
          <v-radio
            label="Si"
            value="true"
          >
          </v-radio>
          <v-radio
            label="No"
            value="false"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense v-if="respuestas.asistencia.respuestaUsuario=== 'true'">
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <p class="ml-8 mr-8">¿Tienes algun tipo de intolerancia? En caso afirmativo indicalas:</p>
      </v-col>
    </v-row>
    
    <v-row dense v-if="respuestas.asistencia.respuestaUsuario=== 'true'">
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="respuestas.intolerancia.respuestaUsuario" @change="onChangeIntolerancias">
          <v-radio
            label="Si"
            value="true"
          >
          </v-radio>
          <v-radio
            label="No"
            value="false"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
      
    </v-row>
    <v-row dense v-if="respuestas.intolerancia.respuestaUsuario === 'true' && respuestas.asistencia.respuestaUsuario=== 'true'">
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
          <v-select 
              dense outlined hide-details
              :items="IntoleranciasOptions"
              item-value="value"
              item-text="label"
              label="Intolerancias"
              v-model="respuestas.intoleranciaOption.respuestaUsuario"
              @change="onChangeIntolerancias"
              :menu-props="{ offsetY: true }">
          </v-select>
      </v-col>
    </v-row>
    <v-row dense v-if="respuestas.intolerancia.respuestaUsuario === 'true' && respuestas.intoleranciaOption.respuestaUsuario ==='Otros' && respuestas.asistencia.respuestaUsuario=== 'true' ">
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-textarea
            cols="55" 
            rows="2"      
            class="mt-0 mb-n7" outlined auto-grow                        
            label="Otras intolerancias"
            v-model="respuestas.intoleranciaOptionOtros.respuestaUsuario">         
        </v-textarea>
      </v-col>
    </v-row>

    <!-- </div> -->

    <v-row dense >
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-btn
          color="primary"
          :loading="loading"
          @click="onClickSaveData"
        >
          Grabar inscripcion
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  // import router from '../router'
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'perAux', 'esEmpleado', 'urlRaiz'])
    },
    data: () => ({
      loading: false,
      cuestionario: {
        Respuestas: []
      },
      IntoleranciasOptions: [
        { label: 'Ninguna', value: 'Ninguna' },
        { label: 'Gluten', value: 'Gluten' },
        { label: 'Lacteos', value: 'Lacteos' },
        { label: 'Vegetariano', value: 'Vegetariano' },
        { label: 'Vegano', value: 'Vegano' },
        { label: 'Otros', value: 'Otros' }
      ],
      
      respuestas: {
        asistencia: { PreguntasCuestionarioId: 12, respuestaUsuario: null },
        intolerancia: { PreguntasCuestionarioId: 13, respuestaUsuario: null },
        intoleranciaOption: { PreguntasCuestionarioId: 14, respuestaUsuario: 'Ninguna' },
        intoleranciaOptionOtros: { PreguntasCuestionarioId: 15, respuestaUsuario: '' }
      },
      // mostrarAlergenos: false
    }),

    methods: {
      // validate () {
      //   this.$refs.form.validate()
      //   if (this.valid)
      //   {
      //       this.enviar();
      //   }
      // },
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Titulo: 'FIESTA EL RINCÓN OBJETIVOS 2023'
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Cuestionario;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el cuestionario');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Respuestas: [ this.respuestas.asistencia, this.respuestas.intolerancia, this.respuestas.intoleranciaOption, this.respuestas.intoleranciaOptionOtros ],
            CuestionarioId: this.cuestionario.CuestionarioId,
            PerId: this.perId,
            DirId: 0
              //DirId: this.perAux != null && this.perAux.Dir != null && this.perAux.Dir.length > 0 ?
              //this.perAux.Dir[0].DirId : null
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/respuesta/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Success;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el cuestionario');
            }
          });
        })
      },
      loadData(){
        this.getDataFromApi()
          .then(data => {
            this.cuestionario = data.item;
          });
      },
      onClickSaveData() {
        if (!this.esEmpleado) {
          alert('INSCRIPCION NO GUARDADA CORRECTAMENTE - Estas intentando guardar la participación desde el usuario de la tienda, inicia sesión desde tu intranet PERSONAL para grabar la inscripción');
          this.$router.push('/');
          return;
        }


        this.postDataToApi()
          .then(data => {
            const success = data.item;
            if (success)
            {
              alert('Muchas gracias por tu participación, se ha guardado correctamente');
              this.$router.push('/');
            }
          });
      },

      onChangeIntolerancias() {
        this.respuestas.intoleranciaOptionOtros.respuestaUsuario = '';
      },
      // showAlergenos() {
      //   this.mostrarAlergenos = true
      // },
      // hideAlergenos(){
      //   this.mostrarAlergenos = false
      // }
    },
    mounted() {
      this.loadData();
    }
  }
</script>